import {BrowserRouter, Navigate, Routes, Route, useNavigate} from "react-router-dom";
import {Box, Button, CssBaseline, Link, ThemeProvider, Typography, useTheme} from "@mui/material";
import {useMemo, userMemo} from "react";
import {themeSettings} from "./theme.js";
import {useSelector} from "react-redux";
import {createTheme} from "@mui/material/styles";


import './App.css';
import MainPage from "./scenes/MainPage";
import PrimarySchool from "./scenes/PrimarySchool";
import MiddleSchool from "./scenes/MiddleSchool";
import PrimarySchoolClass from "./scenes/PrimarySchool";


function NotFound() {
    const navigate = useNavigate()
    const theme = useTheme();
    const neutralLight = theme.palette.common.light;
    const dark = theme.palette.primary.dark;
    const primary = theme.palette.primary.main;
    const background = theme.palette.background.default;
    const primaryLight = theme.palette.primary.light;
    const alt = theme.palette.background.alt;

    return (
        <Box>
            <Typography color={primary} fontSize={"2rem"} padding={"1rem"}>Oops! You seem to be lost.</Typography>
            <Typography color={primary} fontSize={"2rem"} padding={"1rem"}>Here are some helpful links:</Typography>
            <Button onClick={() => (navigate("/"))}>Home</Button>
            <Button onClick={() => (navigate("/primary_school"))}>Szkoła podstawowa</Button>
            <Button onClick={() => (navigate("/middle_school"))}>Liceum</Button>
        </Box>
    )
}

function App() {
    const mode = useSelector((state) => state.mode);
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    return (
        <ThemeProvider theme={theme}>
            <div className="app">
                <BrowserRouter basename="/">
                    <CssBaseline/>
                    <Routes>
                        <Route path="/" element={<MainPage/>}/>
                        <Route path="/primary_school" element={<PrimarySchoolClass/>}/>
                        <Route path="/middle_school" element={<MiddleSchool/>}/>
                        {/*<Route path="/technical_school" element={<TechnicalSchool/>}/>*/}
                        <Route path='*' element={<NotFound/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
        </ThemeProvider>
    );
}

export default App;
