import {Box, Typography} from "@mui/material";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import React from "react";

const VideoContainer = ({title, video_id, additional_text}) => {
    return (
        <Box sx={{alignItems: 'center'}}>
            {/*<Typography*/}
            {/*    variant="h3"*/}
            {/*    sx={{marginLeft: '5px'}}>*/}
            {/*    {title}*/}
            {/*</Typography>*/}
            <Box sx={{alignItems: 'center'}} style={{display: (video_id ? 'block':'none')}}>
                <LiteYouTubeEmbed
                    id={video_id}
                    title=""
                />
            </Box >
            <Typography
                variant="h4"
                sx={{marginLeft: '5px', alignItems: 'center'}}>
                {additional_text}
            </Typography>
        </Box>
    )
}

export default VideoContainer;