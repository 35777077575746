import {Box, Typography, useTheme, useMediaQuery} from "@mui/material";
import Navbar from "../NavigationBar";

import {SimpleTreeView} from '@mui/x-tree-view/SimpleTreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';


import React from "react";
import VideoContainer from "components/VideoContainer";
import {loremIpsum} from "lorem-ipsum";

let VIDEO_ID = "L2vS_050c-M";

const MiddleSchool = () => {
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

    return (
        <Box>
            <Navbar/>
            <Box
                width="100%"
                padding="2rem 15%"
                display={isNonMobileScreens ? "flex" : "block"}
                gap="0.5rem"
                justifyContent="space-between"
            >
                <Typography>
                    {loremIpsum({count: 10})}
                </Typography>
            </Box>
            <Box
                width="70%"
                padding="2rem 6%"
                // display={isNonMobileScreens ? "flex" : "block"}
                gap="0.5rem"
                justifyContent="space-between"
            >
                <SimpleTreeView>
                    <TreeItem itemId="grid" label="Rozdział 1">
                        <TreeItem itemId={1} label={
                            <VideoContainer title={"title"} video_id={VIDEO_ID} additional_text={"additional_text"} />
                        }
                        />
                        <TreeItem itemId={2} label={
                            <VideoContainer title={"title"} video_id={VIDEO_ID} additional_text={"additional_text"} />
                        }
                        />
                        <TreeItem itemId={3} label={
                            <VideoContainer title={"title"} video_id={VIDEO_ID} additional_text={"additional_text"} />
                        }
                        />
                    </TreeItem>
                    <TreeItem itemId="pickers" label="Rozdział 2">
                        <TreeItem itemId={4} label={
                            <VideoContainer title={"title"} video_id={VIDEO_ID} additional_text={"additional_text"} />
                        }
                        />
                        <TreeItem itemId={5} label={
                            <VideoContainer title={"title"} video_id={VIDEO_ID} additional_text={"additional_text"} />
                        }
                        />
                    </TreeItem>
                    <TreeItem itemId="charts" label="Rozdział 3">
                        <TreeItem itemId={6} label={
                            <VideoContainer title={"title"} video_id={VIDEO_ID} additional_text={"additional_text"} />
                        }
                        />
                    </TreeItem>
                    <TreeItem itemId="tree-view" label="Rozdział 4">
                        <TreeItem itemId={7} label={
                            <VideoContainer title={"title"} video_id={VIDEO_ID} additional_text={"additional_text"} />
                        }
                        />
                    </TreeItem>
                </SimpleTreeView>
            </Box>
        </Box>
    );
};

export default MiddleSchool;
