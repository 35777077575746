import {Box, Typography, useTheme, useMediaQuery, Link, Button} from "@mui/material";
import Navbar from "../NavigationBar";

import {SimpleTreeView} from '@mui/x-tree-view/SimpleTreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';


import React from "react";
import VideoContainer from "components/VideoContainer";
import { useNavigate } from "react-router-dom";

import { loremIpsum } from "lorem-ipsum";

const MainPage = () => {
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
    const navigate = useNavigate();

    return (
        <Box>
            <Navbar/>
            <Box
                width="100%"
                padding="2rem 15%"
                display={isNonMobileScreens ? "flex" : "block"}
                gap="0.5rem"
                justifyContent="space-between"
            >
                <Typography>
                    {loremIpsum({count: 100})}
                </Typography>
            </Box>
            {/*<Box*/}
            {/*    width="70%"*/}
            {/*    padding="2rem 6%"*/}
            {/*    // display={isNonMobileScreens ? "flex" : "block"}*/}
            {/*    gap="0.5rem"*/}
            {/*    justifyContent="space-between"*/}
            {/*>*/}
            {/*    <Button href="/szkola_podstawowa">*/}
            {/*        Szkoła podstawowa*/}
            {/*    </Button>*/}
            {/*    <Button href="/liceum">*/}
            {/*        Liceum*/}
            {/*    </Button>*/}
            {/*</Box>*/}

        </Box>

    );
};

export default MainPage;
