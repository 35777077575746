import {Box, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";
import {TreeItem} from "@mui/x-tree-view/TreeItem";
import VideoContainer from "components/VideoContainer";
import React, {useId, useState} from "react";
import {v4 as uuidv4} from 'uuid';
import {Image} from 'mui-image'
import FlexBetween from "components/FlexBetween";

const Section = ({data}) => {
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

    const main_label = data["main_label"]

    let subsections = data["subsections"]

    const active = data["active"]

    return (
        (active ? (
            <TreeItem key={uuidv4()} itemId={uuidv4()} label={
                <Typography fontSize={"2rem"} padding={"1rem"}>
                    {main_label}
                </Typography>
            }>
                {subsections.map(({active, section_title, video_path, image, images, additional_text}) => (
                    (active ? (
                            <TreeItem key={uuidv4()} itemId={uuidv4()} label={
                                <Typography fontSize={"1.5rem"} padding={"1rem"}>
                                    {section_title}
                                </Typography>
                            }>
                                <VideoContainer key={uuidv4()} title={section_title} video_id={video_path}
                                                additional_text={
                                                    <Box padding="1rem 0 1rem 0" sx={{alignItems: 'center'}}>
                                                        <Image src={image["path"]}
                                                               disableError={true}
                                                               onError={() => ("")} errorIcon={""}
                                                               height={image["scale"]}
                                                               width={image["scale"]}
                                                        />
                                                        <div dangerouslySetInnerHTML={{__html: additional_text}}/>
                                                    </Box>}
                                />
                                <Box>
                                    <FlexBetween width="75%" sx={{"object-fit": "contain"}} padding="5rem">
                                        {(images.map((x) => (
                                            <Image src={x["path"]} key={uuidv4()} itemId={uuidv4()}
                                                   disableError={true}
                                                   onError={() => ("")} errorIcon={""}
                                                   height={x["scale"]}
                                                   width={x["scale"]}
                                            />
                                        )))}
                                    </FlexBetween>
                                </Box>
                            </TreeItem>
                        ) :
                        (""))

                ))}
            </TreeItem>
        ) : (""))
    );
};

export default Section;
