import {Box, Typography, useTheme, useMediaQuery} from "@mui/material";
import Navbar from "scenes/NavigationBar";

import {SimpleTreeView} from '@mui/x-tree-view/SimpleTreeView';
import {v4 as uuidv4} from 'uuid';

import React from "react";
import Section from "./section";


const ClassView = ({json_data}) => {
    const {palette} = useTheme();
    const primary_dark = palette.primary.dark;
    const primary_medium = palette.primary.medium;
    const primary_main = palette.primary.main;
    const secondary_dark = palette.secondary.dark;
    const secondary_medium = palette.secondary.medium;
    const secondary_main = palette.secondary.main;
    const background = palette.background.gray;


    return (
        <Box
            width="100%"
            padding="1rem 6%"

            gap="2rem"
            justifyContent="space-between"
        >
            <Typography fontSize={"2rem"} padding={"1rem"}>Klasa 7</Typography>
            <SimpleTreeView>
                {json_data.map((value) => (
                    <Section key={uuidv4()} data={value}/>
                ))}
            </SimpleTreeView>
        </Box>
    )
}


const PrimarySchoolClass = (params) => {
    const {palette} = useTheme();
    const primary_dark = palette.primary.dark;
    const primary_medium = palette.primary.medium;
    const primary_main = palette.primary.main;
    const secondary_dark = palette.secondary.dark;
    const secondary_medium = palette.secondary.medium;
    const secondary_main = palette.secondary.main;

    const school_type = "PrimarySchool/"
    const class_no = "class_7/"

    const json_data = require("scenes/" + school_type + class_no + "primary_school_data.json");

    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

    const text = "<!DOCTYPE html>" +
        "<p>Welcome, dear students, to seventh grade," +
        "<br/>" +
        "Where numbers and shapes are waiting to parade." +
        "<br/>" +
        "Math is our guide on this journey today," +
        "<br/>" +
        "Let’s dive into puzzles, come join the play!" +
        "<br/>" +
        "<br/>" +
        "Equations and patterns, they’re more than they seem," +
        "<br/>" +
        "They unlock new worlds, like a bright shining dream." +
        "<br/>" +
        "No need to worry or feel any dread," +
        "<br/>" +
        "We’ll solve them together—step by step led." +
        "<br/>" +
        "<br/>" +
        "So I invite you now to a game full of fun," +
        "<br/>" +
        "Where math is the magic and learning’s begun." +
        "<br/>" +
        "Are you ready to start? Let’s give it a try—" +
        "<br/>" +
        "In this math adventure, we’ll soar and fly high!" +
        "</p>"

    return (
        <Box>
            <Navbar/>
            <Box
                width="100%"
                padding="2rem 15%"
                display={isNonMobileScreens ? "flex" : "block"}
                gap="0.5rem"
                justifyContent="flex-end"
                align="center"
                color={primary_dark}
            >
                <Box width="100%">
                    <Typography fontWeight="bold" variant="h4">
                        <div dangerouslySetInnerHTML={{__html: text}}/>
                    </Typography>
                    <Typography variant="h6">
                        Unknown
                    </Typography>
                </Box>
            </Box>
            <Box padding="2rem 5%" color={primary_dark} width="100%" justifyContent="space-between">
                <ClassView json_data={json_data}/>
            </Box>
        </Box>
    );
};

export default PrimarySchoolClass;
