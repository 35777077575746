import React, {useState} from "react";
import {
    Box,
    IconButton,
    Container,
    Typography,
    MenuItem,
    useTheme,
    Button,
    AppBar,
    Toolbar,
    Menu,
} from "@mui/material";

import {useNavigate} from "react-router-dom";
import {Image} from "mui-image";
import MenuIcon from '@mui/icons-material/Menu';
import FlexBetween from "components/FlexBetween";


const pages = [
    {
        "is_active": true,
        "page": "Szkoła podstawowa",
        "link_part": "/primary_school",
        "subpages": [
            {
                "is_active": false,
                "page": "Klasa 1",
                "link_part": "/class_1",
            },
            {
                "is_active": false,
                "page": "Klasa 2",
                "link_part": "/class_2",
            },
            {
                "is_active": false,
                "page": "Klasa 3",
                "link_part": "/class_3",
            },
            {
                "is_active": false,
                "page": "Klasa 4",
                "link_part": "/class_4",
            },
            {
                "is_active": false,
                "page": "Klasa 5",
                "link_part": "/class_5",
            },
            {
                "is_active": false,
                "page": "Klasa 6",
                "link_part": "/class_6",
            },
            {
                "is_active": true,
                "page": "Klasa 7",
                "link_part": "/class_7",
            },
            {
                "is_active": false,
                "page": "Klasa 8",
                "link_part": "/class_8",
            },
        ]
    },
    {
        "is_active": false,
        "page": "Liceum",
        "link_part": "/middle_school",
        "subpages": [
            {
                "is_active": false,
                "page": "Klasa 1",
                "link_part": "/class_1",
            },
            {
                "is_active": true,
                "page": "Klasa 2",
                "link_part": "/class_2",
            },
            {
                "is_active": false,
                "page": "Klasa 3",
                "link_part": "/class_3",
            },
            {
                "is_active": false,
                "page": "Klasa 4",
                "link_part": "/class_4",
            }
        ]
    },
    {
        "is_active": false,
        "page": "Technikum",
        "link_part": "/technical_school",
        "subpages": [
            {
                "is_active": true,
                "page": "Klasa 1",
                "link_part": "/class_1",
            },
            {
                "is_active": true,
                "page": "Klasa 2",
                "link_part": "/class_2",
            },
            {
                "is_active": true,
                "page": "Klasa 3",
                "link_part": "/class_3",
            },
            {
                "is_active": true,
                "page": "Klasa 4",
                "link_part": "/class_4",
            },
            {
                "is_active": true,
                "page": "Klasa 5",
                "link_part": "/class_5",
            }
        ]
    }
]

const MenuOption = ({props}) => {
    const theme = useTheme();
    const neutralLight = theme.palette.common.light;
    const dark = theme.palette.primary.dark;
    const primary = theme.palette.primary.main;
    const background = theme.palette.background.default;
    const primaryLight = theme.palette.primary.light;
    const alt = theme.palette.background.alt;

    const navigate = useNavigate()

    const {is_active, link_part, page, subpages} = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                key={page}
                onClick={handleOpenMenu}
                sx={{
                    my: 2, color: 'white', display: 'block', "&:hover": {
                        color: primaryLight,
                        cursor: "pointer",
                    },
                }}
            >
                <Typography>
                    {page}
                </Typography>
            </Button>
            <Menu
                key="menu-appbar"
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                sx={{
                    display: {xs: 'none', md: 'block'}, "& .MuiPaper-root": {
                        backgroundColor: primary, color: 'white'
                    }
                }}
            >
                {subpages.map((page) => (
                    page["is_active"] ? (
                        <MenuItem key={page["page"]}
                                  onClick={
                                      () => navigate(
                                          link_part
                                          // + page["link_part"]
                                      )
                                  }>
                            <Typography sx={{textAlign: 'center'}}>{page["page"]}</Typography>
                        </MenuItem>) : ("")
                ))}
            </Menu>
        </Box>
    )
}

const NavBar = () => {
    const theme = useTheme();
    const neutralLight = theme.palette.common.light;
    const dark = theme.palette.primary.dark;
    const primary = theme.palette.primary.dark;
    const background = theme.palette.background.default;
    const primaryLight = theme.palette.primary.light;
    const alt = theme.palette.background.alt;

    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const navigate = useNavigate();

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <FlexBetween gap="1rem">
                        <Image src="/logo192.png" height="5rem" width="5rem" style={{"borderRadius": "25%"}}/>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.1rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                "&:hover": {
                                    color: primaryLight,
                                    cursor: "pointer",
                                },
                            }}
                            onClick={() => (navigate("/"))}
                        >
                            Imaginalis
                        </Typography>
                    </FlexBetween>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            key="menu-appbar"
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{display: {xs: 'block', md: 'none'}}}
                        >
                            {pages.map((page) => (
                                page["is_active"] ? (
                                    <MenuItem key={page["page"]} onClick={() => (navigate(page["link_part"]))}>
                                        <Typography sx={{textAlign: 'center'}}>{page["page"]}</Typography>
                                    </MenuItem>) : ("")
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            "&:hover": {
                                color: primaryLight,
                                cursor: "pointer",
                            },
                        }}
                    >
                        Imaginalis
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (
                            page["is_active"] ? (<MenuOption props={page}/>) : ("")
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default NavBar;


// import React, {useState} from "react";
// import {
//     Box,
//     IconButton,
//     InputBase,
//     Typography,
//     Select,
//     MenuItem,
//     FormControl,
//     useTheme,
//     useMediaQuery,
//     Tooltip, Button, AppBar, Toolbar, Avatar,
// } from "@mui/material";
// import {
//     Search,
//     Message,
//     DarkMode,
//     LightMode,
//     Notifications,
//     Help,
//     Menu,
//     Close,
// } from "@mui/icons-material";
// import {useDispatch, useSelector} from "react-redux";
// import {setMode} from "state";
// import {useNavigate} from "react-router-dom";
// import FlexBetween from "components/FlexBetween";
// import {Image} from "mui-image";
//
// const Navbar = () => {
//     const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const userInfo = useSelector((state) => state.userInfo);
//     const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
//
//     const theme = useTheme();
//     const neutralLight = theme.palette.common.light;
//     const dark = theme.palette.primary.dark;
//     const primary = theme.palette.primary.dark;
//     const background = theme.palette.background.default;
//     const primaryLight = theme.palette.primary.light;
//     const alt = theme.palette.background.alt;
//
//     const openNotifications = () => {
//         console.log("notification clicked");
//     };
//
//     return (
//         <FlexBetween padding="1rem 10%" backgroundColor={alt} maxHeight="6rem">
//             <FlexBetween gap="1.75rem">
//                 <Image src="/logo192.png" height="5rem" width="5rem" style={{"borderRadius": "25%"}} />
//                 <Typography
//                     fontWeight="bold"
//                     fontSize="clamp(1rem, 2rem, 2.25rem)"
//                     color={primary}
//                     onClick={() => navigate("/")}
//                     sx={{
//                         "&:hover": {
//                             color: primaryLight,
//                             cursor: "pointer",
//                         },
//                     }}
//                 >
//                     Imaginalis
//                 </Typography>
//                 <Typography
//                     onClick={() => navigate("/szkola_podstawowa")} fontWeight="bold"
//                     color={primary}
//                     fontSize="clamp(0.75rem, 1.25rem, 1.5rem)" sx={{
//                     "&:hover": {
//                         color: primaryLight,
//                         cursor: "pointer",
//                     },
//                 }}>
//                     Szkoła podstawowa
//                 </Typography>
//                 <Typography
//                     // onClick={() => navigate("/liceum")}
//                     fontWeight="bold" color={primary}
//                     fontSize="clamp(0.75rem, 1.25rem, 1.5rem)" sx={{
//                     "&:hover": {
//                         color: primaryLight,
//                         cursor: "pointer",
//                     },
//                 }}>
//                     Liceum
//                 </Typography>
//             </FlexBetween>
//
//             {/* DESKTOP NAV */}
//             {isNonMobileScreens ? (
//                 <FlexBetween gap="2rem">
//                     <Tooltip title="Toogle mode">
//                         <IconButton onClick={() => dispatch(setMode())}>
//                             {theme.palette.mode === "dark" ? (
//                                 <DarkMode sx={{fontSize: "25px"}}/>
//                             ) : (
//                                 <LightMode sx={{color: dark, fontSize: "25px"}}/>
//                             )}
//                         </IconButton>
//                     </Tooltip>
//                     {/*<Tooltip title="Messages">*/}
//                     {/*    <IconButton*/}
//                     {/*        onClick={openNotifications()}*/}
//                     {/*    >*/}
//                     {/*        <Message sx={{color: dark, fontSize: "25px"}}/>*/}
//                     {/*    </IconButton>*/}
//                     {/*</Tooltip>*/}
//                     {/*<Tooltip title="Notifications">*/}
//                     {/*    <IconButton onClick={openNotifications()}>*/}
//                     {/*        <Notifications sx={{color: dark, fontSize: "25px"}}/>*/}
//                     {/*    </IconButton>*/}
//                     {/*</Tooltip>*/}
//                     {/* <IconButton>
//             <Help sx={{ color: dark, fontSize: "25px" }} />
//           </IconButton> */}
//                 </FlexBetween>
//             ) : (
//                 <IconButton
//                     onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
//                 >
//                     <Menu/>
//                 </IconButton>
//             )}
//
//             {/*/!* MOBILE NAV *!/*/}
//             {/*{!isNonMobileScreens && isMobileMenuToggled && (*/}
//             {/*    <Box*/}
//             {/*        position="fixed"*/}
//             {/*        right="0"*/}
//             {/*        bottom="0"*/}
//             {/*        height="100%"*/}
//             {/*        zIndex="10"*/}
//             {/*        maxWidth="500px"*/}
//             {/*        minWidth="300px"*/}
//             {/*        backgroundColor={background}*/}
//             {/*    >*/}
//             {/*        /!* CLOSE ICON *!/*/}
//             {/*        <Box display="flex" justifyContent="flex-end" p="1rem">*/}
//             {/*            <IconButton*/}
//             {/*                onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}*/}
//             {/*            >*/}
//             {/*                <Close />*/}
//             {/*            </IconButton>*/}
//             {/*        </Box>*/}
//
//             {/*        /!* MENU ITEMS *!/*/}
//             {/*        <FlexBetween*/}
//             {/*            display="flex"*/}
//             {/*            flexDirection="column"*/}
//             {/*            justifyContent="center"*/}
//             {/*            alignItems="center"*/}
//             {/*            gap="3rem"*/}
//             {/*        >*/}
//             {/*            <Tooltip title="Toogle mode">*/}
//             {/*                <IconButton*/}
//             {/*                    onClick={() => dispatch(setMode())}*/}
//             {/*                    sx={{ fontSize: "25px" }}*/}
//             {/*                >*/}
//             {/*                    {theme.palette.mode === "dark" ? (*/}
//             {/*                        <DarkMode sx={{ fontSize: "25px" }} />*/}
//             {/*                    ) : (*/}
//             {/*                        <LightMode sx={{ color: dark, fontSize: "25px" }} />*/}
//             {/*                    )}*/}
//             {/*                </IconButton>*/}
//             {/*            </Tooltip>*/}
//             {/*            <Tooltip title="Messages">*/}
//             {/*                <IconButton*/}
//             {/*                    onClick={() => navigate(`/conversations/${userInfo._id}`)}*/}
//             {/*                >*/}
//             {/*                    <Message sx={{ fontSize: "25px" }} />*/}
//             {/*                </IconButton>*/}
//             {/*            </Tooltip>*/}
//             {/*            <Tooltip title="Notifications">*/}
//             {/*                <IconButton onClick={() => openNotifications()}>*/}
//             {/*                    <Notifications sx={{ fontSize: "25px" }} />*/}
//             {/*                </IconButton>*/}
//             {/*            </Tooltip>*/}
//             {/*            /!* <Help sx={{ fontSize: "25px" }} /> *!/*/}
//             {/*            <FormControl variant="standard" value={userInfo.name}>*/}
//             {/*                <Select*/}
//             {/*                    value={userInfo.name}*/}
//             {/*                    sx={{*/}
//             {/*                        backgroundColor: neutralLight,*/}
//             {/*                        width: "150px",*/}
//             {/*                        borderRadius: "0.25rem",*/}
//             {/*                        p: "0.25rem 1rem",*/}
//             {/*                        "& .MuiSvgIcon-root": {*/}
//             {/*                            pr: "0.25rem",*/}
//             {/*                            width: "3rem",*/}
//             {/*                        },*/}
//             {/*                        "& .MuiSelect-select:focus": {*/}
//             {/*                            backgroundColor: neutralLight,*/}
//             {/*                        },*/}
//             {/*                    }}*/}
//             {/*                    input={<InputBase />}*/}
//             {/*                >*/}
//             {/*                    <MenuItem value={userInfo.name}>*/}
//             {/*                        <Typography>{userInfo.name}</Typography>*/}
//             {/*                    </MenuItem>*/}
//             {/*                    <MenuItem onClick={() => navigate(`/profile/${userInfo._id}`)}>*/}
//             {/*                        <Typography>Profile</Typography>*/}
//             {/*                    </MenuItem>*/}
//             {/*                    <MenuItem onClick={() => navigate(`/settings/${userInfo._id}`)}>*/}
//             {/*                        <Typography>Settings</Typography>*/}
//             {/*                    </MenuItem>*/}
//             {/*                    <MenuItem onClick={() => navigate("/contact")}>*/}
//             {/*                        <Typography>Contact</Typography>*/}
//             {/*                    </MenuItem>*/}
//             {/*                </Select>*/}
//             {/*            </FormControl>*/}
//             {/*        </FlexBetween>*/}
//             {/*    </Box>*/}
//             {/*)}*/}
//         </FlexBetween>
//     );
// };
//
// export default Navbar;
